<template>
  <section class="new-offer mt-5">
    <div class="container">
      <page-title class="mb-5">
        回应报价
      </page-title>

      <div v-if="noReceiveItem" class="py-3 px-4 text-white mb-3" style="background:#723336">
        <fa :icon="['fa', 'info-circle']" />
        <span class="pl-2">请注意：此次报价中您无法获得任何饰品。</span>
      </div>

      <trade-card :offer-item="offerItem" no-action />
      <check-box v-if="!senderIsMe && offerItem.status === 10" v-model="agreed" class="mx-auto">
        <div class="text-secondary text-9">
          我已仔细检查报价内容，并且确认自己在此次报价中将得到和失去的饰品。
        </div>
      </check-box>

      <div class="d-flex justify-content-center mt-5">
        <btn
          v-if="!senderIsMe && offerItem.status === 10"
          class="btn btn-outline-secondary ml-3"
          :disabled="!agreed"
          @click="reject"
        >
          拒绝报价
        </btn>
        <btn
          v-if="!senderIsMe && offerItem.status === 10"
          class="btn btn-outline-primary ml-3"
          :disabled="!agreed"
          @click="accept"
        >
          接受报价
        </btn>
        <btn v-if="senderIsMe && offerItem.status === 10" class="btn btn-outline-secondary ml-3" @click="cancel">
          取消报价
        </btn>
      </div>
    </div>
  </section>
</template>

<script>
import { OfferService } from '@/services/api'
import TradeCard from './TradeCard.vue'
import { errorParser } from '@/utils/errorParser'
import { mapGetters } from 'vuex'

export default {
  name: 'OfferDetail',
  components: {
    TradeCard,
  },
  data() {
    return {
      id: this.$router.currentRoute.params.id,
      offerItem: {},
      agreed: false,
    }
  },
  computed: {
    ...mapGetters(['user']),
    senderIsMe() {
      return this.offerItem?.sender?.id === this.user?.info?.id
    },
    noReceiveItem() {
      return (
        (this.senderIsMe && this.offerItem?.receiver_inventories?.length === 0) ||
        (!this.senderIsMe && this.offerItem?.sender_inventories?.length === 0)
      )
    },
  },
  mounted() {
    this.hydratePage()
  },
  methods: {
    toast(message, type = 'warning') {
      this.$bvToast.toast(message, {
        title: message,
        toaster: 'b-toaster-bottom-right',
        variant: type,
        solid: true,
      })
    },
    hydratePage() {
      this.loading = true
      OfferService.getOfferById(this.id)
        .then(({ data }) => {
          this.offerItem = data
        })
        .catch(e => {
          if (e) this.toast(errorParser(e.response).message, 'danger')
        })
        .finally(() => {
          this.loading = false
        })
    },
    accept() {
      this.loading = true
      OfferService.acceptOffer(this.offerItem.offer_id)
        .then(() => {
          this.toast('接受报价成功', 'success')
          this.hydratePage()
        })
        .catch(e => {
          if (e) this.toast(errorParser(e.response).message, 'danger')
        })
        .finally(() => {
          this.loading = false
        })
    },
    reject() {
      this.loading = true
      OfferService.rejectOffer(this.offerItem.offer_id)
        .then(() => {
          this.toast('已拒绝报价', 'success')
          this.hydratePage()
        })
        .catch(e => {
          if (e) this.toast(errorParser(e.response).message, 'danger')
        })
        .finally(() => {
          this.loading = false
        })
    },
    cancel() {
      this.loading = true
      OfferService.cancelOffer(this.offerItem.offer_id)
        .then(() => {
          this.toast('已取消报价', 'success')
          this.hydratePage()
        })
        .catch(e => {
          console.log(e.response)
          if (e) this.toast(errorParser(e.response).message, 'danger')
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../styles/style.scss';

.trade-card {
  width: 100%;
  margin-bottom: 20px;
  position: relative;

  &:hover {
    .trade-card__mask {
      opacity: 0;
    }
  }

  &__mask {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    z-index: 1;
    background: rgba($color: #000000, $alpha: 0.2);
    transition: 0.3s ease;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 80px;
    padding: 30px;
    background: $bg-opacity;
    clip-path: polygon(
      0% 0%,
      100% 0%,
      100% 60px,
      calc(100% - 5px) calc(100% - 19.37px),
      calc(100% - 10px) calc(100% - 17.32px),
      calc(100% - 15px) calc(100% - 13.23px),
      calc(100% - 16px) calc(100% - 12px),
      calc(100% - 17px) calc(100% - 10.54px),
      calc(100% - 18px) calc(100% - 8.72px),
      calc(100% - 19px) calc(100% - 6.25px),
      calc(100% - 19.5px) calc(100% - 4.44px),
      calc(100% - 20px) 100%,
      20px 100%,
      19.5px calc(100% - 4.44px),
      19px calc(100% - 6.25px),
      18px calc(100% - 8.72px),
      17px calc(100% - 10.54px),
      15px calc(100% - 13.23px),
      10px calc(100% - 17.32px),
      5px calc(100% - 19.37px),
      0 60px
    );
    border-bottom: 1px dashed #2d3139;
  }
  &__body {
    width: 100%;
    padding: 30px;
    background: $bg-opacity;
    border-top: 1px dashed #2d3139;
    border-bottom: 1px dashed #2d3139;
    clip-path: polygon(
      0% 20px,
      5px 19.37px,
      10px 17.32px,
      15px 13.23px,
      16px 12px,
      17px 10.54px,
      18px 8.72px,
      19px 6.25px,
      19.5px 4.44px,
      20px 0%,
      calc(100% - 20px) 0%,
      calc(100% - 19.5px) 4.44px,
      calc(100% - 19px) 6.25px,
      calc(100% - 18px) 8.72px,
      calc(100% - 17px) 10.54px,
      calc(100% - 16px) 12px,
      calc(100% - 15px) 13.23px,
      calc(100% - 10px) 17.32px,
      calc(100% - 5px) 19.37px,
      100% 20px,
      100% 100%,
      0 100%
    );
  }
  &__footer {
    width: 100%;
    padding: 20px;
    background: $bg-opacity;
    display: flex;
    justify-content: space-between;
  }
}
</style>
